/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { MagazineCollection } from "../issue-landing-page";
import "../issue-landing-page/issue-landing-page.m.css";
import { MagazineEditions } from "@quintype/arrow";
import { defaultDarkTheme, defaultTheme } from "../../../constants";

export const MagazineLandingPage = ({ data = {}, config = {} }) => {
  const { "magazine-landing": magazineLanding = {}, enableMagazine = false } = get(
    config,
    ["pagebuilder-config", "magazine"],
    {}
  );
  const { rows: magazineRows = [], pageSetting = {} } = magazineLanding;
  if (!enableMagazine) {
    return <div styleName="info-message">Magazine configuration is missing.</div>;
  }
  const enableDarkMode = useSelector((state) => get(state, ["header", "isDarkModeEnabled"], false));
  const firstEditionCollection = get(data, ["firstEditionCollection", "collection"], {});
  const { otherEditions = [], magazineSlug = "", magazineTitle = "", magazineDescription = "" } = data;
  return (
    <>
      {magazineRows.map((row, index) => {
        const { name, layout, config } = row;
        switch (name) {
          case "headerEdition": {
            const collection = {
              "associated-metadata": {
                config: {
                  pageDescription: magazineDescription,
                  ...config,
                  pageTitle: magazineTitle,
                  enableBorder: true,
                  customUrlPath: `subscription?group=${magazineSlug}`,
                },
                layout: layout,
              },
            };
            return (
              <MagazineCollection key={name} collection={collection} layout={layout} metadata={config} index={index} />
            );
          }
          case "otherEditions":
            if (otherEditions.length) {
              return (
                <div styleName="editions-wrapper">
                  <MagazineEditions
                    key={name}
                    collection={otherEditions}
                    config={{
                      ...config,
                      magazineSlug,
                      isArchivePage: true,
                      theme: enableDarkMode
                        ? get(config, ["darkTheme"], get(pageSetting, ["darkBgColor"], defaultDarkTheme))
                        : get(config, ["theme"], get(pageSetting, ["bgColor"], defaultTheme)),
                    }}
                    showLoadmore={config.showButton}
                  />
                </div>
              );
            }
            break;
          case "topStories":
            return (
              <MagazineCollection
                key={name}
                collection={firstEditionCollection}
                layout={layout}
                metadata={{ ...config, navigate: false, magazineSlug }}
                index={index}
              />
            );
        }
      })}
    </>
  );
};

MagazineLandingPage.propTypes = {
  data: PropTypes.shape({
    otherEditions: PropTypes.array,
    firstEditionCollection: PropTypes.shape({
      collection: PropTypes.object,
    }),
  }),
  config: PropTypes.shape({
    "pagebuilder-config": PropTypes.shape({
      magazine: PropTypes.shape({
        rows: PropTypes.array,
        pageSetting: PropTypes.object,
      }),
    }),
  }),
};
