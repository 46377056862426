/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { MagazineCollection } from "../issue-landing-page";
import { FilterEditions } from "./magazine-editions";
import { useSelector } from "react-redux";
import "../issue-landing-page/issue-landing-page.m.css";
import { defaultDarkTheme, defaultTheme } from "../../../constants";

export const MagazineArchivePage = ({ data = {}, config = {} }) => {
  const { archive = {}, enableMagazine = false } = get(config, ["pagebuilder-config", "magazine"], {});
  const { rows: magazineRows = [], pageSetting = {} } = archive;
  if (!enableMagazine) return <div styleName="info-message">Magazine configuration is missing.</div>;

  const { magazineDescription = "", magazineSlug = "", magazineTitle = "" } = data;
  const enableDarkMode = useSelector((state) => get(state, ["header", "isDarkModeEnabled"], false));
  return (
    <>
      {magazineRows.map((row, index) => {
        const { name, layout, config } = row;
        switch (name) {
          case "headerEdition": {
            const collection = {
              "associated-metadata": {
                config: {
                  pageDescription: magazineDescription,
                  ...config,
                  pageTitle: magazineTitle,
                  enableBorder: true,
                  customUrlPath: `subscription?group=${magazineSlug}`,
                },
                layout: layout,
              },
            };
            return (
              <MagazineCollection key={name} collection={collection} layout={layout} metadata={config} index={index} />
            );
          }
          case "allEditions": {
            const backgroundColor = enableDarkMode
              ? get(config, ["darkTheme"], get(pageSetting, ["darkBgColor"], defaultDarkTheme))
              : get(config, ["theme"], get(pageSetting, ["bgColor"], defaultTheme));
            return <FilterEditions row={row} data={data} bgColor={backgroundColor} />;
          }
        }
      })}
    </>
  );
};

MagazineArchivePage.propTypes = {
  data: PropTypes.shape({
    magazineDescription: PropTypes.string,
    magazineSlug: PropTypes.string,
    allEditions: PropTypes.array,
    magazineTitle: PropTypes.string,
  }),
  config: PropTypes.shape({
    "pagebuilder-config": PropTypes.shape({
      magazine: PropTypes.shape({
        archive: PropTypes.shape({
          rows: PropTypes.array,
          pageSetting: PropTypes.object,
        }),
      }),
    }),
  }),
};
