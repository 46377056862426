/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { getCollectionTemplate } from "../../get-collection-template";
import { getDefaultStoriesCount, setSubscriptionRedirect } from "../../../utils";
import isEmpty from "lodash/isEmpty";
import "./issue-landing-page.m.css";
import { MagazineEditions } from "@quintype/arrow";
import { useSelector } from "react-redux";

export const getCollectionStories = (currentEditionCollection = {}, config = {}, layout = "", storiesStartIndex) => {
  const storiesCount = parseInt(config.initialLoadCount) || getDefaultStoriesCount(layout);
  const storiesEndIndex = storiesStartIndex + storiesCount;
  const collectionItems = currentEditionCollection.items.slice(storiesStartIndex, storiesEndIndex);
  storiesStartIndex = storiesStartIndex + storiesCount;
  return {
    ...currentEditionCollection,
    items: collectionItems,
    currentOffset: storiesStartIndex,
  };
};

export const MagazineCollection = (props) => {
  const { layout, ...restProps } = props;
  const updatedConfig = () => {
    switch (layout) {
      case "ArrowThreeColSevenStory":
        return {
          config: {
            ...restProps.metadata,
            showButton: false,
            navigate: false,
          },
        };
      case "ArrowFourColGrid":
        return {
          config: {
            initialLoadCount: 8,
            ...restProps.metadata,
            footerButton: "SubsequentLoadCount",
            navigate: false,
            subsequentLoadCount: 8,
          },
        };
      default:
        return {
          config: {
            ...restProps.metadata,
          },
        };
    }
  };
  const Component = getCollectionTemplate(layout);
  return React.createElement(Component, {
    collection: {
      ...restProps.collection,
      config: {
        ...restProps.collection.config,
        theme: get(restProps, ["metadata", "theme"]),
        darkTheme: get(restProps, ["metadata", "darkTheme"]),
      },
    },
    metadata: { ...updatedConfig() },
    index: restProps.index,
  });
};
MagazineCollection.propTypes = {
  layout: PropTypes.string,
};

export const IssueLandingPage = ({ data = {}, config = {} }) => {
  const { "issue-landing": issueLanding = {}, enableMagazine = false } = get(
    config,
    ["pagebuilder-config", "magazine"],
    {}
  );
  const { rows: magazineRows = [], pageSetting = {} } = issueLanding;
  if (!enableMagazine) return <div styleName="info-message">Magazine configuration is missing.</div>;

  const currentEdition = get(data, ["currentEdition", "collection"]);
  if (!currentEdition) return <div styleName="info-message">No editions found.</div>;
  const enableDarkMode = useSelector((state) => get(state, ["header", "isDarkModeEnabled"], false));
  const {
    topStoriesCollection,
    allStoriesCollection,
    magazineSlug = "",
    otherEditions = [],
    magazineTitle = "",
  } = data;
  const sketchesHost = useSelector((state) => get(state, ["qt", "config", "sketches-host"]));

  let storiesStartIndex = 0;

  return (
    <>
      {magazineRows.map((row, index) => {
        const { name, layout, config } = row;
        switch (name) {
          case "headerEdition": {
            const customUrlPath = `subscription?group=${magazineSlug}`;
            config.customUrlPath = customUrlPath;
            config.onSubscriptionClick = () => setSubscriptionRedirect(`/${customUrlPath}`, sketchesHost);
            const metadata = {
              ...config,
              magazineTitle,
              magazinePageUrl: `/magazine/${magazineSlug}`,
            };
            return (
              <MagazineCollection
                key={name}
                collection={currentEdition}
                layout={layout}
                metadata={metadata}
                index={index}
              />
            );
          }
          case "otherEditions":
            if (otherEditions.length) {
              return (
                <div styleName="editions-wrapper">
                  <MagazineEditions
                    key={name}
                    collection={otherEditions}
                    config={{
                      ...config,
                      magazineSlug,
                      isArchivePage: true,
                      theme: enableDarkMode
                        ? config.darkTheme || pageSetting.darkBgColor
                        : config.theme || pageSetting.bgColor,
                    }}
                    showLoadmore={config.showButton}
                  />
                </div>
              );
            }
            break;
          case "topStories":
          case "allStories":
            let collection = {};
            const rowCollection = name === "topStories" ? topStoriesCollection : allStoriesCollection;
            if (!isEmpty(rowCollection.collection)) {
              collection = rowCollection.collection;
            } else {
              collection = getCollectionStories(currentEdition, config, layout, storiesStartIndex);
              storiesStartIndex = collection.currentOffset;
            }
            return (
              <MagazineCollection key={name} collection={collection} layout={layout} metadata={config} index={index} />
            );
        }
      })}
    </>
  );
};

IssueLandingPage.propTypes = {
  data: PropTypes.shape({
    currentEdition: PropTypes.shape({
      collection: PropTypes.object,
    }),
    otherEditions: PropTypes.array,
  }),
  config: PropTypes.shape({
    "pagebuilder-config": PropTypes.shape({
      magazine: PropTypes.shape({
        rows: PropTypes.array,
        pageSetting: PropTypes.object,
      }),
    }),
  }),
};
